import "./CommonTable.css";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import noDataImg from "../../assets/nodataavailable.svg";

import { BsSortDown, BsSortUp } from "react-icons/bs";
import { RxCaretSort } from "react-icons/rx";

const CommonTable = ({ columns, data, handleSearchData, buttons, showTableActions = true, searchPlaceholderText, customPageSize }) => {
  const {
    // state: { pageIndex, pageSize = 10 },
    state,
    page, // Add page variable
    nextPage, // Add nextPage function
    previousPage, // Add previousPage function
    canNextPage, // Add canNextPage variable
    canPreviousPage, // Add canPreviousPage variable
    gotoPage, // Function to go to a specific page
    pageCount, // Total number of pages
    PageSize,
    setPageSize, //changing no of rows
    // eslint-disable-next-line no-unused-vars
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    setGlobalFilter,
    getTableBodyProps,
    // state: {pageSize },
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { globalFilter } = state;

  useEffect(() => {
    setGlobalFilter(handleSearchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearchData]);

  const [goToValue, setGoToValue] = useState(1)
  const handleGoToPage = (e) => {
    let page;
    if (e.target.value) {
      page = Math.max(1, Math.min(Number(e.target.value), pageCount));
    }
    setGoToValue(page)
    gotoPage(page - 1);
  }

  return (
    <div className="tableContainer">
      <div className="tableHeader">
        <div />
        <div className="rightTableContent">
          {buttons}
          {showTableActions &&
            <>
              <input
                type="text"
                placeholder={searchPlaceholderText ? searchPlaceholderText : "Search..."}
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="searchInputStyle" maxLength={15}
              />
              <div className="EntryCount">
                <p>Show</p>
                <div />
                <select
                  className="selectEntry"
                  value={customPageSize ? customPageSize : state.pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50]?.map((pageSizeOption) => (
                    <option
                      key={pageSizeOption}
                      value={pageSizeOption}
                      selected={PageSize === pageSizeOption}
                    >
                      &nbsp;1-{pageSizeOption}
                    </option>
                  ))}
                </select>
              </div>
            </>
          }
        </div>
      </div>
      <div className="tableDiv">
        {page.length > 0 ? (
          <>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className="headerwrapper">
                          <div> {column.render("Header")}</div>
                          <div className="icons">
                            {!column.hideSortIcon && (
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <BsSortDown />
                                  ) : (
                                    <BsSortUp />
                                  )
                                ) : (
                                  <RxCaretSort />
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className="nodata">
              <div className="imgcontainer">
                <img src={noDataImg} alt="" />
                <div className="message">
                  <span cl>No Data Available.</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {data.length > 10 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "14px",
            padding: "8px 0px 0px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="endOrStartPage"
            >
              First Page
            </button>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              title="previous"
              className="PreviousNextPage"
            >
              <FaChevronLeft />
            </button>
            <p>
              {state.pageIndex + 1} - {pageCount || "1"}
            </p>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              title="next"
              className="PreviousNextPage"
            >
              <FaChevronRight />
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="endOrStartPage"
            >
              Last Page
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <p>Go to page</p>
            <input
              type="number"
              defaultValue={state.pageIndex + 1}
              value={goToValue}
              onChange={(e) => handleGoToPage(e)}
              style={{
                width: "30px",
                height: "20px",
                border: "1px solid #366FF5",
                borderRadius: "2px",
                padding: "0px 5px",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CommonTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func,
        PropTypes.node,
      ]).isRequired,
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSearchData: PropTypes.any,
  buttons: PropTypes.any,
};

export default CommonTable;

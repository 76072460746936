import React, { useState, useEffect } from "react";
import "./ActivitySnapShot.css";
import { ComponentAndScreenConstant } from "./../../../constants/componentAndScreenConstant";
import { Url } from "../../../EnvoirnmentSetup/APIs";
import { getAPICall } from "../../../APIMethods/APIMethods";
import submitImage from "../../../assets/Insights/QSubmit.svg";
import moment from "moment";
import { ExcelDownload } from "../../../constants/constantFunctions";
import { useSelectedCardContext } from "../../../Context/Context";

const ActivitySnapShot = () => {
  const { setIsLoading, setWarning } = useSelectedCardContext();

  const [allData, setAllData] = useState({
    totalJobPosted: 0,
    totalActiveJobs: 0,
    deletedJobs: 0,
    applicantRegistered: 0,
    UsJobPoster: 0,
    IndianJobPoster: 0,
    posterRegistered: 0,
    expiredJobs: 0,
    totalActiveCompanies: 0,
  });

  // const currentDate = new Date();
  // const previousDate = new Date(currentDate)
  // previousDate.setDate(currentDate.getDate() - 7);

  const [dataByDate, setDataByDate] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // ------------------------------------------- API Integration -----------------------------------------------------

  const allReports = async () => {
    try {
      setIsLoading(true);
      const allData = await getAPICall(Url?.allTotals);

      if (allData?.SUCCESS) {
        setIsLoading(false);
        setAllData({
          totalJobPosted: allData?.DATA?.totalJobs,
          totalActiveJobs: allData?.DATA?.totalActiveJobs,
          deletedJobs: allData?.DATA?.deletedJobs,
          applicantRegistered: allData?.DATA?.totalApplicants,
          UsJobPoster: allData?.DATA?.totalUSJobPosters,
          IndianJobPoster: allData?.DATA?.totalIndianJobPosters,
          posterRegistered: allData?.DATA?.totalJobPosters,
          expiredJobs: allData?.DATA?.totalExpiredJobs,
          totalActiveCompanies: allData?.DATA?.totalActiveCompanies,
        });
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // select the date and submit
  const handleSubmitDate = async () => {
    if(fromDate && toDate){
    try {
      setIsLoading(true);
      const getDateData = await getAPICall(
        Url?.getReportByDates
          .replace("{fromDate}", fromDate)
          .replace("{toDate}", toDate)
      );

      if (getDateData.SUCCESS) {
        const tableData = getDateData?.DATA;
        const result = Object.keys(tableData).map((key, index) => {
          return {
            srno: index + 1,
            parameter: key,
            total: tableData[key],
          };
        });
        setDataByDate(result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } 
  }else{
    setWarning('Please Select From Date and To Date')
  }
  };

  useEffect(() => {
    allReports();
  }, []);

  // -------------------------------------------------------------------------------------------------------------------------

  return (
    <div className="snapshotwrapper">
      <div className="snapshotheader">
        <span>Activity Snapshot</span>
      </div>

      <div className="snapshotdetails">
        <div className="dashboard-container">
          <div className="stats-column">
            <div className="stat-item">
              <span
                className={`stat-number ${allData?.totalJobPosted == 0 && 'dontDownload'}`}
                onClick={() =>
                  allData?.totalJobPosted !== 0 &&
                  ExcelDownload(Url?.downloadExcel + "totalJobs", "JobsPosted")
                }
              >
                {allData?.totalJobPosted}
              </span>{" "}
              Total Jobs Posted
            </div>
            <div className="stat-item">
              <span
                className={`stat-number ${allData?.totalActiveJobs == 0 && 'dontDownload'}`}
                onClick={() =>
                  allData?.totalActiveJobs !== 0 &&
                  ExcelDownload(
                    Url?.downloadExcel + "totalActiveJobs",
                    "CurrentActiveJobs"
                  )
                }
              >
                {allData?.totalActiveJobs}
              </span>{" "}
              Current Active Jobs
            </div>
            <div className="stat-item">
              <span
                className={`stat-number ${allData?.deletedJobs == 0 && 'dontDownload'}`}
                onClick={() =>
                  allData?.deletedJobs !== 0 &&
                  ExcelDownload(
                    Url?.downloadExcel + "deletedJobs",
                    "ArchivedJobs"
                  )
                }
              >
                {allData?.deletedJobs}
              </span>{" "}
              Archived Jobs
            </div>
          </div>
          <div className="stats-column">
            <div className="stat-item">
              <span
                className={`stat-number ${allData?.expiredJobs == 0 && 'dontDownload'}`}
                onClick={() =>
                  allData?.expiredJobs !== 0 &&
                  ExcelDownload(
                    Url?.downloadExcel + "totalExpiredJobs",
                    "ExpiredJobs"
                  )
                }
              >
                {allData?.expiredJobs}
              </span>{" "}
              Expired Jobs
            </div>
            <div className="stat-item">
              <span
                className={`stat-number ${allData?.applicantRegistered == 0 && 'dontDownload'}`}
                onClick={() =>
                  allData?.applicantRegistered !== 0 &&
                  ExcelDownload(
                    Url?.downloadExcel + "totalApplicants",
                    "RegisteredApplicants"
                  )
                }
              >
                {allData?.applicantRegistered}
              </span>{" "}
              Registered Students
            </div>
            <div className="stat-item">
              <span
                className={`stat-number ${allData?.posterRegistered == 0 && 'dontDownload'}`}
                onClick={() =>
                  allData?.posterRegistered !== 0 &&
                  ExcelDownload(
                    Url?.downloadExcel + "totalJobPosters",
                    "RegisteredRecruiters"
                  )
                }
              >
                {allData?.posterRegistered}
              </span>{" "}
              Registered Placement Coordinators
            </div>
          </div>
        </div>

        <div className="datefilter">
          <div className="daterange">
            <div className="fromDate">
              <ComponentAndScreenConstant.DatePickerField
                labelTxt="From Date"
                inputType="date"
                inputId={`startDate`}
                inputClass="startDateInput"
                placeholderText={"DD/MM/YYYY"}
                selectedValue={fromDate}
                onChange={(e) => {setFromDate(moment(e).format("YYYY-MM-DD"));setToDate('')}}
                inputWrapperStyle="startDateWrapper"
                // errMsg={errors?.tillDate?.errorField}
                dateFormat={"dd/MMM/yyy"}
                placeholderTxt="Enter Active Till Date"
                isRequired={true}
                maxDate={new Date()}
              />
            </div>
            <div className="todate">
              <ComponentAndScreenConstant.DatePickerField
                labelTxt="To Date"
                inputType="date"
                inputId={`startDate`}
                inputClass="startDateInput"
                placeholderText={"DD/MM/YYYY"}
                selectedValue={toDate}
                onChange={(e) => setToDate(moment(e).format("YYYY-MM-DD"))}
                inputWrapperStyle="startDateWrapper"
                dateFormat={"dd/MMM/yyy"}
                minDate={fromDate ? new Date(fromDate) : new Date() }
                maxDate={new Date()}
                placeholderTxt="Enter Active Till Date"
                isRequired={true}
              />
            </div>
            <div className="action">
              <button className="submitDate" onClick={handleSubmitDate}>
                {/* <img
                  src={submitImage}
                  alt="submitimg"
                  style={{ width: "5vw" }}
                /> */}
                Submit
              </button>
            </div>
          </div>
        </div>

        {dataByDate.length > 0 && (
          <div className="displaytabledta">
            <table>
              <thead>
                <th>S/N</th>
                <th>Parameter</th>
                <th>Total</th>
              </thead>
              <tbody>
                {dataByDate?.map((item, i) => {
                  const getParameterName = (parameter) => {
                    switch (parameter) {
                      case "uniqueApplicants":
                        return "Unique Students";
                      case "totalNewJobPosters":
                        return "Total New Coordinators";
                      case "totalJobs":
                        return "Total Jobs";
                      case "applicantNotifications":
                        return "Student Notifications";
                      case "jobPosterNotifications":
                        return "Placement Coordinator Notifications";
                      case "uniqueJobPostersWeb":
                        return "Unique Placement Coordinator";
                      default:
                        return parameter;
                    }
                  };
                  // {console.log(item?.parameter);
                  // }
                  let disabled = (item?.total !== 0 && (item?.parameter !== "applicantNotifications" || item?.parameter !== "jobPosterNotifications"));
                  return (
                    <tr key={`key${i + 1}`}>
                      <td>{item?.srno}</td>
                      <td>{getParameterName(item?.parameter)}</td>
                      <td>
                        <button
                          style={{
                            cursor: (item?.parameter === "applicantNotifications" || item?.parameter === "jobPosterNotifications") && "not-allowed" ,
                            color: (item?.parameter === "applicantNotifications" || item?.parameter === "jobPosterNotifications") ? 'black' : 'blue'
                          }}
                          disabled={!disabled}
                          className={`${disabled ? "downloadFile" : "dontDownload"}`}
                          onClick={() =>
                            disabled &&
                            ExcelDownload(
                              Url?.downloadExcelDateWise
                                ?.replace("{key}", item?.parameter)
                                .replace(
                                  "{fromDate}",
                                  moment(fromDate).format("yyyy-MM-DD")
                                )
                                .replace(
                                  "{toDate}",
                                  moment(toDate).format("yyyy-MM-DD")
                                ),
                              item?.parameter
                            )
                          }
                        >
                          {item?.total}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivitySnapShot;

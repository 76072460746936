import React, { useEffect, useState } from "react";
import "./PlacementSummeryReport.css";
import { useSelectedCardContext } from "../../../Context/Context";
import { getAPICall } from "../../../APIMethods/APIMethods";
import { Url } from "../../../EnvoirnmentSetup/APIs";
import CommonTable from "../../../Components/table/CommonTable";
import latestJobIcon from "../../../assets/latestJobIcon.svg";
import { ComponentAndScreenConstant } from "../../../constants/componentAndScreenConstant";
import moment from "moment";
import { ExcelDownload } from "../../../constants/constantFunctions";

const PlacementSummeryReport = () => {
  const { setIsLoading } = useSelectedCardContext();
  const [tableData, setTableData] = useState([]);
  const [tableFilteredData, setTableFilteredData] = useState([]);
  const [counts, setCounts] = useState()
  const [companyFilterValue, setCompanyFilterValue] = useState();
  const [companyFilterOptions, setCompanyFilterOptions] = useState([])
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [jobTypeFilterValue, setJobTypeFilterValue] = useState();

  const jobTypeFilterOptions = [
    { label: 'All Jobs', value: 'all' },
    { label: 'Campus Jobs', value: 'campusJobs' },
    { label: 'QuikHire Jobs', value: 'quikHireJobs' },
  ]

  const tableColumns = [
    {
      Header: "S/N",
      accessor: "srno",
    },
    {
      Header: "Student Name",
      accessor: "studentName",
    },
    {
      Header: "Campus's in-hand offers",
      accessor: "inhandCampus",
      Cell: ({ row }) => (
        <div>
          {row?.original?.inhandCampus?.length > 0
            ? 'Yes (' + row?.original?.inhandCampus?.map((item, i) => item?.job?.companyName).join(', ') + ')'
            : 'No'}
        </div>
      ),
    },
    {
      Header: "QuikHire's in-hand offers",
      accessor: "inhandQuikHire",
      Cell: ({ row }) => (
        <div
        // style={{ maxHeight: '50px', overflowY: 'auto' }}
        >
          {row?.original?.inhandQuikHire?.split('#nextline').map((item, index) => (
            <div key={index + 1}>{item}</div>
          ))}
        </div>
      ),
    },
  ]

  const fetchTableData = async (dates) => {
    try {
      setIsLoading(true);
      const apiUrl = dates ? Url?.getPlacementSummeryReportByDates
        .replace("{fromDate}", fromDate)
        .replace("{toDate}", toDate)
        : Url?.getPlacementSummeryReport
      const tableData = await getAPICall(apiUrl);
      console.log("getPlacementSummeryReport", tableData?.DATA)
      if (tableData.SUCCESS) {
        setIsLoading(false);
        const totalJobs = [];
        let count = 1;
        tableData?.DATA?.users?.forEach((item, i) => {
          const inHandQuikHireJobList = item?.performaceCounts?.selectedJobStatusQuikHire?.selectedJobStatus?.map(job => job?.job?.company?.companyName)
          totalJobs.push({
            srno: count++,
            studentName: item?.appUser?.fullName,
            inhandCampus: item?.performaceCounts?.selectedJobStatusCampus,
            inhandQuikHire: inHandQuikHireJobList?.join(",#nextline") || "-",
            allRowData: item
          })
        })
        setTableData(totalJobs);
        setTableFilteredData(totalJobs)
        setCounts({
          quikHireCount: tableData?.DATA?.quikHireCount,
          campusCount: tableData?.DATA?.campusCount
        })
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchCompanyNames = async () => {
    try {
      const companyData = await getAPICall(Url?.getAllCompanyNames);
      console.log("getAllCompanyNames", companyData?.DATA)
      if (companyData.SUCCESS) {
        const companyList = [
          { label: 'All Companies', value: 'all' }
        ]
        companyData?.DATA?.forEach(company => {
          companyList.push({ label: company, value: company.toLowerCase() })
        })
        setCompanyFilterOptions(companyList)
      }
    } catch (err) {
      console.log("error while getting company names", err);
    }
  }

  useEffect(() => {
    fetchTableData();
    fetchCompanyNames();
  }, []);

  const handleCompanySelectChange = (value) => {
    setCompanyFilterValue(value);
    const updatedCompaniesList = tableData.map((item) => {
      return item.inhandCampus?.filter(job =>
        job?.job?.companyName?.toLowerCase() == value?.toLowerCase()
      ).length > 0 && item
    }).filter(item => item)

    const updatedTable = value == 'all'
      ? tableData
      : updatedCompaniesList

    setTableFilteredData(updatedTable)
  }

  const handleJobTypeSelectChange = (value) => {
    setJobTypeFilterValue(value);

    const updatedTable = value == 'all' ? tableData
      : value == 'campusJobs' ?
        tableData.filter((item) => item?.inhandCampus?.length > 0)
        : tableData.filter((item) => item?.inhandQuikHire != "-")

    setTableFilteredData(updatedTable.filter(item => item))
  }

  const getFormatedFileName = (initialString) => {
    let date = new Date();
    let formattedDate = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    let formattedTime = hours + "." + minutes + "." + seconds + " " + ampm;
    //Extraction Report- 16-9-2023 11.17.28 AM
    return initialString + formattedDate + " " + formattedTime;
  }

  const downloadExcelOfOffered = async (jobType) => {
    if (jobType == 'campus') {
      ExcelDownload(Url?.downloadExcelOfCampusOfferedStudents, getFormatedFileName('Unique Campus Offers'))
    } else {
      ExcelDownload(Url?.downloadExcelOfQuikHireOfferedStudents, getFormatedFileName('Unique QuikHire Offers'))
    }
  }

  return (
    <div className="psr_wrapper">
      <div className="overalljobsheader StudentHeaderText">
        <div className="imageAndText">
          <img src={latestJobIcon} alt="" />
          <span>Placement Summary Report</span>
        </div>
        <div className="psr_count">
          <div className="studentCountContainerDiv">
            <div className="studentCountDiv" style={{ color: "#01774A" }}>
              <span
                style={{ borderColor: "#01774A", fontSize: '12pt', cursor: 'pointer' }}
                onClick={() => downloadExcelOfOffered('campus')}
              >
                {counts?.campusCount}
              </span>
              Unique Campus Offers
            </div>
            <div className="studentCountDiv" style={{ color: "#EA6200" }}>
              <span
                style={{ borderColor: "#EA6200", fontSize: '12pt', cursor: 'pointer' }}
                onClick={() => downloadExcelOfOffered('qh')}
              >
                {counts?.quikHireCount}
              </span>
              Unique QuikHire Offers
            </div>
          </div>
        </div>
      </div>
      <div className="psr_jobpostingstable">
        <div className="psr_countandDate">
          <div className="psr_daterange">
            <div className="fromDate">
              <ComponentAndScreenConstant.DatePickerField
                labelTxt="From Date"
                inputType="date"
                inputId={`startDate`}
                inputClass="startDateInput"
                placeholderText={"DD/MM/YYYY"}
                selectedValue={fromDate}
                onChange={(e) => { setFromDate(moment(e).format("YYYY-MM-DD")); setToDate('') }}
                inputWrapperStyle="startDateWrapper"
                dateFormat={"dd/MMM/yyy"}
                placeholderTxt="Enter Active Till Date"
                isRequired={true}
                maxDate={new Date()}
              />
            </div>
            <div className="todate">
              <ComponentAndScreenConstant.DatePickerField
                labelTxt="To Date"
                inputType="date"
                inputId={`startDate`}
                inputClass="startDateInput"
                placeholderText={"DD/MM/YYYY"}
                selectedValue={toDate}
                onChange={(e) => setToDate(moment(e).format("YYYY-MM-DD"))}
                inputWrapperStyle="startDateWrapper"
                dateFormat={"dd/MMM/yyy"}
                minDate={fromDate ? new Date(fromDate) : new Date()}
                maxDate={new Date()}
                placeholderTxt="Enter Active Till Date"
                isRequired={true}
              />
            </div>
            <div className="action">
              <button className="submitDate" onClick={() => fetchTableData('dates')}>
                Submit
              </button>
            </div>
          </div>
        </div>
        <CommonTable
          data={tableFilteredData}
          columns={tableColumns}
          searchPlaceholderText={'Search Student Name...'}
          customPageSize={50}
          buttons={
            <>
              <div className="RegisteredStudentstableScoreFilterDiv">
                {/* <label>Company Name</label> */}
                <select
                  className="selectEntry RegisteredStudentstableScoreFilterSelect"
                  value={companyFilterValue}
                  onChange={(e) => {
                    handleCompanySelectChange(e.target.value)
                  }}
                >
                  {companyFilterOptions?.map((item, i) => (
                    <option
                      key={i + 1}
                      value={item.value}
                      selected={companyFilterValue === item.value}
                    >
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="RegisteredStudentstableScoreFilterDiv">
                {/* <label>Job Type</label> */}
                <select
                  className="selectEntry RegisteredStudentstableScoreFilterSelect"
                  value={jobTypeFilterValue}
                  onChange={(e) => {
                    handleJobTypeSelectChange(e.target.value)
                  }}
                >
                  {jobTypeFilterOptions?.map((item, i) => (
                    <option
                      key={i + 1}
                      value={item.value}
                      selected={jobTypeFilterValue === item.value}
                    >
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
            </>
          }
        />
      </div>
    </div>
  )
}

export default PlacementSummeryReport;

import React, { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import "./CampusAdminDashboard.css";
import createIcon from "../../assets/CreateIcon.svg";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useSelectedCardContext } from "../../Context/Context";
import {
  emailValidationForJobPoster,
  emailValidationNonMandatory,
  jobDescription,
  onlyAlphabetsWithComa,
  onlyNumberGreaterThanzero,
  onlySalaryEntry,
} from "../../Validation/Validation";
import { LoginAutonavigateEnv, PortalUserRole, Url } from "../../EnvoirnmentSetup/APIs";
import {
  ApiCall,
  deleteAPICall,
  getAPICall,
} from "../../APIMethods/APIMethods";
import moment from "moment";
import closePopup from "../../assets/closepopup.svg";
import { getBaseURLTonavigatetoLogin } from "../../EnvoirnmentSetup/profiles";

const CampusAdminDashboard = () => {
  const locationData = useLocation();
  const {
    selectedCard,
    setSelectedCard,
    setIsLoading,
    setWarning,
    setAlert,
    jobModal,
    setJobModal,
    jobCreateDetails,
    setJobCreateDetails,
  } = useSelectedCardContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});
  const [errors, setErrors] = useState({});
  const [allKeyWords, setAllKeyWords] = useState([]);
  const [allExperience, setAllExperience] = useState([]);
  const [allJobType, setAllJobType] = useState([]);
  const [allWorkMode, setAllWorkMode] = useState([]);
  const [allWorkLocation, setAllWorkLocation] = useState([]);
  const [allDesignations, setAllDesignations] = useState([])
  let path = locationData.pathname;

  const navigate = useNavigate()

  const [showHero, setShowhero] = useState(false);
  useEffect(() => {
    path.includes("latestJob") || path.includes("quickhirejobs") ? setShowhero(true) : setShowhero(false);
    let userData = JSON.parse(sessionStorage.getItem('user'))
    if (userData.roles[0]?.name != PortalUserRole) {
      let WebPath = window.location.href
      window.location.href = getBaseURLTonavigatetoLogin(LoginAutonavigateEnv, userData.roles[0]?.name) + '#' + WebPath.split('#')[1]
    }
  }, [path]);

  useEffect(() => {

    getAPICall(Url.instituteAll)
      .then((res) => {
        if (res.DATA?.length == 0) {
          navigate('/Main/InstituteInfo')
        }
      })
    getAPICall(Url.getAllDesignation)
      .then((res) => {
        const newArray = [];
        res?.DATA?.map((item) => {
          newArray.push({
            value: item?.designationId,
            label: item?.designationName,
          });
        });
        setAllDesignations(newArray)
      })
    getAPICall(Url.getAllKeywords).then((res) => {
      const newArray = [];
      res?.DATA?.map((item) => {
        newArray.push({
          value: item?.skillId,
          label: item?.name,
        });
      });
      setAllKeyWords(newArray);
    });
    getAPICall(Url.GetExperienceList).then((res) => {
      const newArray = [];
      res?.DATA?.map((item) => {
        newArray.push({
          value: item?.experienceId,
          label: item?.experience,
        });
      });
      setAllExperience(newArray);
    });
    getAPICall(Url.GetJobType).then((res) => {
      const newArray = [];
      res?.DATA?.map((item) => {
        newArray.push({
          value: item?.jobTypeId,
          label: item?.jobType,
        });
      });
      setAllJobType(newArray);
    });
    getAPICall(Url.GetWorkMode).then((res) => {
      const newArray = [];
      res?.DATA?.map((item) => {
        newArray.push({
          value: item?.workModeId,
          label: item?.workMode,
        });
      });
      setAllWorkMode(newArray);
    });
    getAPICall(Url.getAllLocation).then((res) => {
      const newArray = [];
      res?.DATA?.map((item) => {
        newArray.push({
          value: item?.stateId,
          label: item?.stateName,
        });
      });
      setAllWorkLocation(newArray);
    });
    closeJobModal();
  }, []);

  const closeJobModal = () => {
    setJobModal(false);
    setJobCreateDetails({
      salary: "",
      isSalaryVisible: true,
      tillDate: moment().add(20, 'days').format('YYYY-MM-DD'),
      description: "",
      designation: [],
      state: [],
      experience: null,
      skill: [],
      messageAuditId: 0,
      isWeb: true,
      jobType: null,
      workMode: null,
      noOfOpenings: 1,
      companyName: "",
      companyEmail: "",
      notificationScore: 0,
    });
  };
  const handleJobInputChange = (field, value) => {
    // onlyAlphabetsWithComa(field,value)
    setTouchedFields((prevState) => ({ ...prevState, [field]: true }));
    setJobCreateDetails((prevDetails) => {
      switch (field) {
        case "designationName":
          return {
            ...prevDetails,
            designation: [
              {
                designationId: typeof value.value === "string" ? null : value.value,
                designationName: value.label,
                ...value,
              },
            ],

          };
        case "stateName":
          return {
            ...prevDetails,
            state: value.map((item) => ({
              stateId: item.value || null,
              stateName: item.label,
              ...item,
            })),
          };

        case "experienceId":
          return {
            ...prevDetails,
            experience: {
              experienceId: value.value,
              ...value,
            },
          };
        case "jobTypeId":
          return {
            ...prevDetails,
            jobType: {
              jobTypeId: value.value,
              ...value,
            },
          };
        case "workModeId":
          return {
            ...prevDetails,
            workMode: {
              workModeId: value.value,
              ...value,
            },
          };
        case "skill":
          return {
            ...prevDetails,
            skill: value.map((item) => ({
              skillId: typeof item.value === "string" ? undefined : item.value,
              name: item.label,
              ...item,
            })),
          };
        case "tillDate":
          return {
            ...prevDetails,
            tillDate: moment(value).format("YYYY-MM-DD"),
          };
        case "noOfOpenings":
          if (value?.length <= 3) {
            return {
              ...prevDetails, [field]: value
            }
          } else {
            return {
              ...prevDetails
            }
          }
        default:
          return {
            ...prevDetails,
            [field]: value,
          };
      }
    });
  };

  const handleCreateJob = () => {
    const regex = /^(?=.*[a-zA-Z])[\d\D]+$/;
    let Data = {
      ...jobCreateDetails,
      tillDate: moment(jobCreateDetails?.tillDate).format('YYYY-MM-DD'),
      skill:
        jobCreateDetails?.skill?.map((v) => ({
          skillId: v.skillId,
          name: v.name,
        })) ?? [],
      jobType: { jobTypeId: jobCreateDetails?.jobType?.jobTypeId },
      workMode: { workModeId: jobCreateDetails?.workMode?.workModeId },
      experience: { experienceId: jobCreateDetails?.experience?.experienceId },
      state:
        jobCreateDetails?.state?.map((v) => ({
          stateId: v.stateId,
          stateName: v.stateName,
        })) ?? [],
    };
    if (!!!jobCreateDetails?.designation[0]?.designationName) {
      setWarning("Job Title is required.");
      return;
    } else if (!regex.test(jobCreateDetails?.designation[0]?.designationName)) {
      setWarning("Job Title cannot contain only numbers.");
      return;
    } else if (!!errors?.designationName?.errorField) {
      setWarning("Please enter valid Job Title.");
      return;
    }
    else if (jobCreateDetails?.skill.filter((v) => !!v?.name)?.length <= 0) {
      setWarning("Select Keywords.");
      return;
    } else if (!!!jobCreateDetails?.companyName) {
      setWarning("Company Name is required.");
      return;
    } else if (!!errors?.companyName?.errorField) {
      setWarning("Please enter valid Company Name.");
      return;
    }
    else if (!!errors?.companyEmail?.errorField) {
      setWarning("Please enter valid Company Email.");
      return;
    }
    else if (!!!jobCreateDetails?.tillDate) {
      setWarning("Select Job Active Till date.");
      return;
    } else if (
      jobCreateDetails?.state.filter((v) => !!v?.stateId)?.length <= 0
    ) {
      setWarning("Select Job Location.");
      return;
    } else if (!!errors?.salary?.errorField) {
      setWarning("Please enter valid Salary details.");
      return;
    }
    else if (!jobCreateDetails?.workMode?.workModeId) {
      setWarning("Select Work Mode.");
      return;
    } else if (!!!jobCreateDetails?.jobType?.jobTypeId) {
      setWarning("Select Job Type.");
      return;
    } else if (!!!jobCreateDetails?.experience?.experienceId) {
      setWarning("Select Experience.");
      return;
    } else if (!!errors?.noOfOpenings?.errorField) {
      setWarning("Please enter valid No. of Openings.");
      return;
    }
    else if (!!!jobCreateDetails?.description) {
      setWarning("Job Description is required.");
      return;
    } else if (!!errors?.description?.errorField) {
      setWarning("Please enter valid Job Description.");
      return;
    }
    else if (jobCreateDetails?.description?.length < 250) {
      setWarning("The job description should be more than 250 characters long.");
      return;
    }
    setIsLoading(true);
    ApiCall(
      Url.getAllJobs,
      jobCreateDetails?.jobId ? "PUT" : "POST",
      true,
      "",
      Data
    ).then((res) => {
      setIsLoading(false);
      if (res.SUCCESS) {
        // getAllJobs()
        closeJobModal();
        setAlert(
          !jobCreateDetails?.jobId
            ? `Job posted successfully, notifying the top ${res?.DATA?.notifiedUser} qualified students.`
            : `Job updated successfully, notifying the top ${res?.DATA?.notifiedUser} qualified students.`
        );
        navigate('/Main/latestJob')
      } else {
        setWarning(res?.message);
      }
    });
  };

  useEffect(() => {
    const newErrors = {};
    Object.keys(touchedFields).forEach((field) => {
      let fieldName = field;
      let value = jobCreateDetails[field];
      if (field === "designationName") {
        fieldName = "Job Title";
        value = jobCreateDetails?.designation[0]?.designationName;
      } else if (field === "stateName") {
        fieldName = "Job Location";
        value = jobCreateDetails?.state[0]?.stateName;
      } else if (field === "skill") {
        fieldName = "Skills";
        value = jobCreateDetails?.skill[0]?.name;
      }
      if (field == "salary") {
        fieldName = "Salary";
        newErrors[field] = onlySalaryEntry(fieldName, value);
      } else if (field == "noOfOpenings") {
        fieldName = "No. Of Openings";
        // newErrors[field] = onlyNumberGreaterThanzero(fieldName, value);
      } else if (field == "companyEmail") {
        fieldName = "Company Email Id";
        newErrors[field] = emailValidationNonMandatory(fieldName, value);
      } else if (field == "description") {
        fieldName = "Description";
        newErrors[field] = { errorField: jobCreateDetails?.description ? '' : 'Description is Required' }
      } else if (field === "tillDate") {
        newErrors[field] = {
          errorField: jobCreateDetails?.tillDate
            ? ""
            : "Active Till Date is Required",
        };
      } else if (field === "designationName") {
        newErrors[field] = { errorField: jobCreateDetails?.designation[0]?.designationName ? '' : 'Job Title is Required' }
      } else if (field === "workModeId") {
        // newErrors[field] = { errorField: jobCreateDetails?.workMode?.workModeId ? '' : 'Work Mode is Required' }
      } else if (field === "jobTypeId") {
        // newErrors[field] = { errorField: jobCreateDetails?.jobType?.jobTypeId ? '' : 'Job Type is Required' }
      } else if (field === "experienceId") {
        // newErrors[field] = { errorField: jobCreateDetails?.experience?.experienceId ? '' : 'Experience is Required' }
      } else {
        newErrors[field] = onlyAlphabetsWithComa(fieldName, value);
      }
    });
    setErrors(newErrors);
  }, [touchedFields]);
  const handleDeleteJob = () => {
    setIsLoading(true);
    deleteAPICall(
      Url.getJobDetailsByJobId.replace("{id}", jobCreateDetails?.jobId)
    ).then((res) => {
      setIsLoading(false);
      if (res.SUCCESS) {
        // getAllJobs()
        closeJobModal();
        setAlert("Job Deleted Successfully!");
      } else {
        setWarning(res.message);
      }
    });
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const openJobModal = () => {
    setJobModal(true);
    setErrors({})
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (newValue) => {
    if (newValue.includes(',') && newValue) {
      const pastedItems = newValue.split(',').map(item => item.trim());

      const newOptions = pastedItems.map(item => {
        // Check if it's already in the list
        if (item.length > 0) {
          const existingOption = allKeyWords.find(
            opt => opt?.label?.toLowerCase() === item?.toLowerCase()
          );
          return existingOption ? existingOption : { value: item, label: item }; // Use item as value for uniqueness
        }
      });

      // Combine both arrays
      let combinedOptions = [
        ...selectedOptions.filter(option => option.value !== undefined),
        // Filter out existing undefined values
        ...newOptions.filter(option => option !== undefined),
      ];

      // Create a Set to track unique values
      let uniqueValues = new Set();

      // Filter combined options to include only unique entries
      let uniqueOptions = combinedOptions.filter(option => {
        if (uniqueValues.has(option.value)) {
          return false;
        } else {
          uniqueValues.add(option.value);
          return true;
        }
      });

      // Get existing skills and merge with uniqueOptions
      const existingSkills = jobCreateDetails?.skill || [];
      const updatedSkills = [...existingSkills, ...uniqueOptions].filter((option, index, self) =>
        index === self.findIndex(opt => opt.value === option.value)
      ); // Ensures uniqueness

      console.log(updatedSkills);

      // setJobCreateDetails({
      //   ...jobCreateDetails,
      //   skill: updatedSkills, // Update the skill field with merged data
      // });"skill", e, 0
      handleJobInputChange('skill', updatedSkills, 0)
      setInputValue(''); // Clear the input after processing
    } else {
      setInputValue(newValue);
    }
  };

  const scoreFilterOptions = [
    { label: 'Select All', value: 0 },
    { label: 'More than 40%', value: 40 },
    { label: 'More than 50%', value: 50 },
    { label: 'More than 60%', value: 60 },
    { label: 'More than 70%', value: 70 },
    { label: 'More than 80%', value: 80 },
    { label: 'More than 90%', value: 90 }
  ]

  return (
    <div className="CampusAdminDashboardSection">
      <div className="CampusAdminDashboardMainDiv">
        <div
          id="default-sidebar"
          className={`CampusAdminDashboardSidebar sidebar ${isSidebarOpen ? "visible" : "hidden"
            }`}
        >
          <div className="closeBtnDiv">
            <button onClick={closeSidebar} id="close" className="close-btn">
              X
            </button>
          </div>
          <ComponentAndScreenConstant.Sidebar onClick={closeSidebar} />

          <div className="allReserverd" style={{ textAlign: "center" }}>
            {/* {window.location.href?.toLocaleLowerCase().match('latestjob') &&  */}
            <div className="btnDiv">
              <button
                type="button"
                id="createJob"
                onClick={() => {
                  // openJobModal();
                  closeJobModal()
                  navigate('/Main/createJob')
                }}
              >
                <img src={createIcon} alt="" /> Create Job
              </button>
            </div>
            {/*  } */}
            <div>
              ©{new Date().getFullYear()} CampusPlacement.in
              <br />
              All rights reserved.
            </div>
          </div>
        </div>
        <div className="CampusAdminDashboardContentDiv">
          <button
            onClick={toggleSidebar}
            id="toggle"
            className="e-btn toggleBtn"
            style={{ zIndex: 9 }}
            aria-label="Toggle Sidebar"
          >
            <RxHamburgerMenu />
          </button>
          <ComponentAndScreenConstant.Header />
          {showHero ? (
            <ComponentAndScreenConstant.HeroSection />
          ) : (
            <></>
          )}
          <Routes>
            <Route
              path="/latestJob"
              element={<ComponentAndScreenConstant.JobDashboard />}
            />
            <Route
              path="/TeamManagement"
              element={<ComponentAndScreenConstant.TeamManagement />}
            />
            <Route
              path="/QuikHireShow"
              element={<ComponentAndScreenConstant.QuikHireCountShow />}
            />
            <Route
              path="/StudentRepo"
              element={<ComponentAndScreenConstant.StudentRepository />}
            />
            <Route
              path="/registeredStudent"
              element={<ComponentAndScreenConstant.RegisteredStudents />}
            />
            {/* {PortalUserRole === "ROLE_MANAGER" && */}
            <Route
              path="/InstituteInfo"
              element={<ComponentAndScreenConstant.InstituteInfo />}
            />
            {/* } */}
            <Route
              path="/jobDetails"
              element={<ComponentAndScreenConstant.JobDetails />}
            />
            <Route
              path="/jobStatus"
              element={<ComponentAndScreenConstant.JobDetails />}
            />
            <Route
              path="/jobDetails/:jobId"
              element={<ComponentAndScreenConstant.JobDetails />}
            />
            <Route
              path="/userProfile"
              element={<ComponentAndScreenConstant.UserProfile />}
            />
            <Route
              path="/appliedJobs"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/viewedJobs"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/sentToJobs"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/shortlistedList"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/interViewScheduled"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/hiredCandidates"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/declinedCandidates"
              element={<ComponentAndScreenConstant.JobStatusDetails />}
            />
            <Route
              path="/recruiterInfo"
              element={<ComponentAndScreenConstant.RecruiterInfo />}
            />
            <Route
              path="/notification"
              element={<ComponentAndScreenConstant.Notification />}
            />
            <Route
              path="/quickhirejobs"
              element={<ComponentAndScreenConstant.QuickHireJobs />}
            />
            {/*  ---------- Insights Rotings  ----------------- */}
            <Route
              path="/reportsdashboard"
              element={<ComponentAndScreenConstant.Insights />}
            />
            <Route
              path="/overalljobpostingdetails"
              element={<ComponentAndScreenConstant.OverAllJobPostings />}
            />

            <Route
              path="/placementsummeryreport"
              element={<ComponentAndScreenConstant.PlacementSummeryReport />}
            />

            <Route
              path="/activitysnapshot"
              element={<ComponentAndScreenConstant.ActivitySnapShot />}
            />
            {/*  ---------- Insights Rotings  ----------------- */}
            <Route path='/createJob' element={<ComponentAndScreenConstant.CreateJob />} />

          </Routes>
        </div>
      </div>
      <ComponentAndScreenConstant.CommonModal
        isOpen={jobModal}
        onRequestClose={closeJobModal}
        className="createJobModal"
      >
        <>
          <img
            src={closePopup}
            alt="closeicon"
            className="closeIcon"
            onClick={closeJobModal}
          />
          <div className="jobModalHeader">
            Let’s {jobCreateDetails?.jobId ? "update" : "create"} your job post
          </div>
          <div className="jobCreateModalBody">
            <div className="leftMainDiv">
              {/* <ComponentAndScreenConstant.InputText
                labelTxt="Job Title"
                inputType="text"
                inputId={`jobTitle`}
                inputClass="jobTitleInput"
                inputStyle={{ borderRadius: "5px" }}
                inputValue={jobCreateDetails?.designation[0]?.designationName}
                onChange={(e) =>
                  handleJobInputChange("designationName", e.target.value)
                }
                inputWrapperStyle="companyNameWrapper"
                errMsg={errors?.designationName?.errorField}
                placeholderTxt="Enter Job Title"
                isRequired={true}
                maxLength={60}
              /> */}
              <ComponentAndScreenConstant.SelectField
                labelTxt="Job Title"
                inputId="designation"
                isCreatable={true}
                selectedValue={jobCreateDetails?.designation ?? "Select...."}
                onChange={(e) => handleJobInputChange("designationName", e)}
                inputWrapperStyle="designationWrapper"
                selectOptions={allDesignations}
                isMultiValue={false}
                placeholderTxt={"Select Job Title"}
                menuPlacement={"auto"}
                errMsg={errors?.designationName?.errorField}
                isRequired={true}
              />
              <ComponentAndScreenConstant.InputText
                labelTxt="Company Name"
                inputType="text"
                inputId={`companyName`}
                inputClass="jobTitleInput"
                inputStyle={{ borderRadius: "5px" }}
                inputValue={jobCreateDetails?.companyName}
                onChange={(e) =>
                  handleJobInputChange("companyName", e.target.value)
                }
                inputWrapperStyle="companyNameWrapper"
                errMsg={errors?.companyName?.errorField}
                placeholderTxt="Enter Company Name"
                isRequired={true}
                maxLength={60}
              />
              <ComponentAndScreenConstant.InputText
                labelTxt='Company Email Id'
                inputType='text'
                inputId={`openings`}
                inputClass='jobTitleInput'
                inputStyle={{ borderRadius: '5px' }}
                inputValue={jobCreateDetails?.companyEmail}
                onChange={(e) => handleJobInputChange('companyEmail', e.target.value)}
                inputWrapperStyle='companyNameWrapper'
                errMsg={errors?.companyEmail?.errorField}
                placeholderTxt='Enter Company Email Id'
                isRequired={true}
              />
            </div>
            <div className="jobKeywordDetails">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Keywords"
                inputId="designation"
                isCreatable={true}
                selectedValue={jobCreateDetails?.skill ?? "Select...."}
                onChange={(e) => handleJobInputChange("skill", e, 0)}
                inputWrapperStyle="designationWrapper keywordStyle"
                selectOptions={allKeyWords}
                isMultiValue={true}
                inputValue={inputValue}
                selectID={"KeywordDetails"}
                onInputChange={handleInputChange}
                placeholderTxt={
                  "Select Keywords : Engineer, Developer, etc Java, PHP, etc Supply Chain, HR, etc. PMP, SCRUM, ITIL, etc"
                }
                menuPlacement={"auto"}
                // errMsg={errors?.skill?.errorField}
                isRequired={true}
                maxSelected={10}
              />
              <ComponentAndScreenConstant.DatePickerField
                labelTxt="Active Till"
                inputType="date"
                inputId={`startDate`}
                inputClass="startDateInput"
                placeholderText={"DD/MM/YYYY"}
                selectedValue={jobCreateDetails?.tillDate}
                onChange={(e) => handleJobInputChange("tillDate", e)}
                inputWrapperStyle="startDateWrapper"
                errMsg={errors?.tillDate?.errorField}
                dateFormat={"dd/MMM/yyy"}
                minDate={new Date()}
                placeholderTxt="Enter Active Till Date"
                isRequired={true}
              />

            </div>

            <div className="leftmainDiv">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Job Location"
                inputId="designation"
                isCreatable={true}
                selectID={"LocationDetails"}
                selectedValue={jobCreateDetails?.state ?? "Select...."}
                onChange={(e) => handleJobInputChange("stateName", e)}
                inputWrapperStyle="designationWrapper"
                selectOptions={allWorkLocation}
                isMultiValue={true}
                placeholderTxt={"Job Location"}
                menuPlacement={"auto"}
                errMsg={errors?.stateName?.errorField}
                isRequired={true}
                maxSelected={5}
              />
            </div>
            <div className="jobKeywordDetails salary">
              <ComponentAndScreenConstant.InputText
                labelTxt="Salary"
                inputType="text"
                inputId={`salary`}
                inputClass="jobTitleInput"
                inputStyle={{ borderRadius: "5px" }}
                inputValue={jobCreateDetails?.salary}
                onChange={(e) => handleJobInputChange("salary", e.target.value)}
                inputWrapperStyle="companyNameWrapper"
                errMsg={errors?.salary?.errorField}
                placeholderTxt="Enter Salary (In LPA)"
                isRequired={false}
                maxLength={9}
              />
            </div>
            <div className="leftMainDiv">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Work Mode"
                inputId="designation"
                isCreatable={true}
                selectedValue={jobCreateDetails?.workMode ?? "Select...."}
                onChange={(e) => handleJobInputChange("workModeId", e)}
                inputWrapperStyle="designationWrapper"
                selectOptions={allWorkMode}
                isMultiValue={false}
                placeholderTxt={"Select Work Mode"}
                menuPlacement={"auto"}
                errMsg={errors?.workModeId?.errorField}
                isRequired={true}
              />
            </div>
            <div className="jobKeywordDetails">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Job Type"
                inputId="designation"
                isCreatable={true}
                selectedValue={jobCreateDetails?.jobType ?? "Select...."}
                onChange={(e) => handleJobInputChange("jobTypeId", e)}
                inputWrapperStyle="designationWrapper"
                selectOptions={allJobType}
                isMultiValue={false}
                placeholderTxt={"Select Job Type"}
                menuPlacement={"auto"}
                errMsg={errors?.jobTypeId?.errorField}
                isRequired={true}
              />
            </div>
            <div className="leftMainDiv">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Experience"
                inputId="designation"
                isCreatable={true}
                selectedValue={jobCreateDetails?.experience ?? "Select...."}
                onChange={(e) => handleJobInputChange("experienceId", e)}
                inputWrapperStyle="designationWrapper"
                selectOptions={allExperience}
                isMultiValue={false}
                placeholderTxt={"Select Experience"}
                menuPlacement={"auto"}
                errMsg={errors?.experienceId?.errorField}
                isRequired={true}
              />
            </div>
            <div className="jobKeywordDetails">
              <ComponentAndScreenConstant.InputText
                labelTxt="No. of Openings"
                inputType="number"
                inputId={`openings`}
                inputClass="jobTitleInput"
                inputStyle={{ borderRadius: "5px" }}
                inputValue={jobCreateDetails?.noOfOpenings}
                onChange={(e) =>
                  handleJobInputChange("noOfOpenings", e.target.value)
                }
                inputWrapperStyle="companyNameWrapper"
                errMsg={errors?.noOfOpenings?.errorField}
                placeholderTxt="Enter No. of Openings"
                isRequired={false}
                maxLength={3}
              />
            </div>
            <div className="leftMainDiv">
              <ComponentAndScreenConstant.SelectField
                labelTxt="Notification Criteria"
                inputId="notificationCriteria"
                isCreatable={true}
                selectedValue={jobCreateDetails?.experience ?? "Select...."}
                onChange={(e) => handleJobInputChange("notificationCriteria", e.value)}
                inputWrapperStyle="designationWrapper"
                selectOptions={scoreFilterOptions}
                isMultiValue={false}
                placeholderTxt={"Select Notification Criteria"}
                menuPlacement={"auto"}
                errMsg={errors?.experienceId?.errorField}
                isRequired={true}
              />
            </div>
            <div className="descriptionMainDiv">
              <ComponentAndScreenConstant.TextArea
                labelTxt="Job Description"
                inputId="description"
                textareaClass="descriptionInput"
                txtAreaStyle={{ borderRadius: "5px" }}
                valueText={jobCreateDetails?.description}
                onChange={(e) =>
                  handleJobInputChange("description", e.target.value)
                }
                txtAreaWrapperStyle="descriptionWrapper"
                errMsg={errors?.description?.errorField}
                placeholderTxt="Enter Job Description"
                isRequired={true}
                maxLength={"10000"}
              />
            </div>
          </div>
          <div className="jobCreateOkBtnModal">
            <button onClick={closeJobModal} className="cancleBtn">
              Cancel
            </button>
            <button onClick={handleCreateJob} className="postJobBtn">
              {jobCreateDetails?.jobId ? "Update Job" : "Post Job"}
            </button>
            {jobCreateDetails?.jobId ? (
              <button
                className="postJobBtn"
                onClick={() => handleDeleteJob()}
                style={{ backgroundColor: "red", borderColor: "red" }}
              >
                {" "}
                Delete
              </button>
            ) : null}
          </div>
        </>
      </ComponentAndScreenConstant.CommonModal>
    </div>
  );
};

export default CampusAdminDashboard;

import React from "react";
import "./Insights.css";
import { PortalUserRole } from "../../EnvoirnmentSetup/APIs";

// Icons Import
import jobPostingsIcon from "../../assets/Insights/jobPostingsIcon.svg";
import activitySnapshot from "../../assets/Insights/activitySnapshot.svg";
import individualReportIcon from "../../assets/Insights/individualReportIcon.svg" 
import nodatafound from "../../assets/Insights/nodata.png";

import { useNavigate } from "react-router-dom";

const Insights = () => {
  const navigate = useNavigate();

  return (
    <div>
      {PortalUserRole == "ROLE_MANAGER" ? (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: " 5px 13px 0",
              marginTop: 0,
            }}
          >
            <p className="breadCrumbDiv1p">
              Reports
            </p>
          </div>
          <div className="ReportsDashboardCardsDiv">
            <div
              className="ReportCards"
              onClick={() => {
                navigate("/Main/overalljobpostingdetails");
              }}
            >
              <div>
                <img src={jobPostingsIcon} alt="jobposticons" />
              </div>
              <div>
                <span>Overall Job Postings</span>
              </div>
            </div>

            <div
              className="ReportCards"
              onClick={() => {
                navigate(
                  "/Main/activitysnapshot"
                );
              }}
            >
              <div>
                <img src={activitySnapshot} alt="activtysnapshot" />
              </div>
              <div>
                <span>Activity Snapshot</span>
              </div>
            </div>

            <div
              className="ReportCards"
              onClick={() => {
                navigate(
                  "/Main/placementsummeryreport"
                );
              }}
            >
              <div>
                <img src={individualReportIcon} alt="activtysnapshot" />
              </div>
              <div>
                <span>Placement Summary Report</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={nodatafound} alt="nodatafound" style={{ width: "20%" }} />
          <span>Oops! No Page Found</span>
        </div>
      )}
    </div>
  );
};

export default Insights;

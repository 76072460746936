import React, { useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import QuikHire from "../../assets/quikHireLogo.png";
import dashboardGrayIcon from "../../assets/dashboardGrayIcon.svg";
import instituteInfo from "../../assets/SidebarIcon/institute_info.svg";
import dashboardActiveIcon from "../../assets/dashboardActiveIcon.svg";
import instituteInfoWhite from "../../assets/SidebarIcon/institute_info_active.svg";
import teamManagementIcon from "../../assets/SidebarIcon/team_management.svg";
import teamManagementIconWhite from "../../assets/SidebarIcon/team_management_active.svg";
import studentRepoIcon from "../../assets/SidebarIcon/student_repo.svg";
import studentRepoIconWhite from "../../assets/SidebarIcon/student_repo_active.svg";
import reportsWhite from '../../assets/SidebarIcon/ReportsWhite.svg'
import reportsGrey from '../../assets/SidebarIcon/reportsGray.svg'
import { useSelectedCardContext } from "../../Context/Context";
import { ModuleCards } from "../../constants/ModuleCards";
import { PortalUserRole } from "../../EnvoirnmentSetup/APIs";

const Sidebar = () => {
  const navigate = useNavigate();
  const { selectedCard, setSelectedCard } = useSelectedCardContext();

  const location = useLocation();
  let path = location.pathname;

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    let url = window.location.href;
    if (!!!token) {
      if (url.toLowerCase().indexOf("/main/jobdetails/") > -1) {
        let urlData = url.split("/");
        sessionStorage.setItem("jobId", urlData[urlData.length - 1]);
      }
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  useEffect(() => {
    if (path.includes("/latestJobs")) {
      setSelectedCard(1);
    } else if (path.includes("/TeamManagement")) {
      setSelectedCard(2);
    } else if (path.includes("/registeredStudent")) {
      setSelectedCard(3);
    } else if (path.includes("/StudentRepo")) {
      setSelectedCard(4);
    } else if (path.includes("/QuikHireShow")) {
      setSelectedCard(5);
    } else if (path.includes("/InstituteInfo")) {
      setSelectedCard(6);
    } else if (path.includes("/quickhirejobs")) {
      setSelectedCard(7);
    } else if (
      path.includes("/reportsdashboard") ||
      path.includes("/overalljobpostingdetails") ||
      path.includes("/placementsummeryreport") ||
      path.includes("/activitysnapshot")
    ) {
      setSelectedCard(8);
    } else {
      setSelectedCard(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <>
      <div className="quikHireLogoDiv">
          <NavLink
            to="/Main/latestJob" >
        <img src={QuikHire} alt="QuikHire Logo" className="quikHireLogo" /> </NavLink>
      </div>
      <ul className="linkingMenu">
        <li>
          <NavLink
            to="/Main/latestJob"
            state={{ screen: "latest" }}
            className={
              selectedCard === ModuleCards?.LatestJobs ? "activeList" : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.LatestJobs
                      ? dashboardActiveIcon
                      : dashboardGrayIcon
                  }
                  alt="Dashboard Icon"
                />{" "}
                Campus Jobs
              </>
            )}
          </NavLink>
        </li>
        {PortalUserRole === "ROLE_MANAGER" && (
          <li>
            <NavLink
              to="/Main/TeamManagement"
              state={{ headerHide: true }}
              className={
                selectedCard === ModuleCards?.teamManagement ? "activeList" : ""
              }
            >
              {({ isActive }) => (
                <>
                  <img
                    src={
                      selectedCard === ModuleCards?.teamManagement
                        ? teamManagementIconWhite
                        : teamManagementIcon
                    }
                    alt="Team Management Icon"
                  />{" "}
                  Campus Team
                </>
              )}
            </NavLink>
          </li>
        )}

        <li>
          <NavLink
            to="/Main/registeredStudent"
            state={{ headerHide: true }}
            className={
              selectedCard === ModuleCards?.regStudent ? "activeList" : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.regStudent
                      ? studentRepoIconWhite
                      : studentRepoIcon
                  }
                  alt="Student Repo Icon"
                />{" "}
                 Registered Students
              </>
            )}
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/Main/StudentRepo"
            state={{ headerHide: true }}
            className={
              selectedCard === ModuleCards?.studentRepo ? "activeList" : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.studentRepo
                      ? studentRepoIconWhite
                      : studentRepoIcon
                  }
                  alt="Student Repo Icon"
                />{" "}
                Pending Students
              </>
            )}
          </NavLink>
        </li> 
        <li>
          <NavLink
            to="/Main/QuikHireShow"
            state={{ headerHide: true }}
            className={
              selectedCard === ModuleCards?.QuikHireShow ? "activeList" : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.QuikHireShow ? studentRepoIconWhite : studentRepoIcon }
                  alt="Student Repo Icon"
                />{" "}
                Placement Records
              </>
            )}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Main/InstituteInfo"
            state={{ headerHide: true }}
            className={
              selectedCard === ModuleCards?.instituteInformation
                ? "activeList"
                : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.instituteInformation
                      ? instituteInfoWhite
                      : instituteInfo
                  }
                  alt="Student Repo Icon"
                />{" "}
                College Information
              </>
            )}
          </NavLink>
        </li>
        {PortalUserRole === "ROLE_MANAGER" && (
        <li>
          <NavLink
            to="/Main/reportsdashboard"
            state={{ headerHide: true }}
            className={
              selectedCard === ModuleCards?.insights ? "activeList" : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.insights
                      ? reportsWhite
                      : reportsGrey
                  }
                  alt="Reports Icon"
                  height={19}
                  width={17}
                />{" "}
                Reports
              </>
            )}
          </NavLink>
        </li>
        )}
        <hr style={{color: 'black', width: '97%', margin: '1%'}}/>
        <li>
          <NavLink
            to="/Main/quickhirejobs"
            state={{ screen: "latest" }}
            className={
              selectedCard === ModuleCards?.quickhirejobs ? "activeList" : ""
            }
          >
            {({ isActive }) => (
              <>
                <img
                  src={
                    selectedCard === ModuleCards?.quickhirejobs
                      ? dashboardActiveIcon
                      : dashboardGrayIcon
                  }
                  alt="Dashboard Icon"
                />{" "}
                QuikHire Jobs
              </>
            )}
          </NavLink>
        </li>
        
      </ul>
    </>
  );
};

export default Sidebar;

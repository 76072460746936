import React from "react";
import "./jobCard.css";
import jobIcon from "../../assets/JobIcon.svg";
import jobLocationIcon from "../../assets/jobLocationIcon.svg";
import { RiEdit2Fill } from "react-icons/ri";
import moment from "moment";
import { PortalUserRole } from "../../EnvoirnmentSetup/APIs";

const jobCard = ({ jobDetails, applied, applicationStatus, onEditClick, screenFlow }) => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const userId = userData.userId;

  const editButton = () => {
    if (
      ((PortalUserRole === "ROLE_RECRUTER" && Number(jobDetails?.createdBy) === Number(userId)) ||
      PortalUserRole === "ROLE_MANAGER") && screenFlow != "quikHireJobs"
    ) {
      return (
        <button className="editJobIcon" onClick={onEditClick}>
          <RiEdit2Fill />
        </button>
      );
    }
    return null; // Return null if none of the conditions are met
  };
  
  

  return (
    <div className="jobCardMainDiv">
      {
        editButton()
      }
     {/* <button className="editJobIcon" onClick={onEditClick}>
            <RiEdit2Fill />
          </button> */}
      <div className="jobIconDiv">
        <img src={jobIcon} alt="" />
      </div>
      <div className="jobDescriptionDiv">
      <small class="noOfOpenings">{jobDetails?.noOfOpenings}  Openings</small>
      {applied && (
          <span>
            {applicationStatus?.closed ? (
              <small className="appliedStatus" style={{ color: "#BC0000" }}>
                Denied
              </small>
            ) : applicationStatus?.selected ? (
              <small
                className="appliedStatus"
                style={{ color: "#FFF", background: "#01774A" }}
              >
                Offered
              </small>
            ) : applicationStatus?.recruiterContacted ? (
              <small className="appliedStatus" style={{ color: "#EA6200" }}>
                Contacted
              </small>
            ) : applicationStatus?.recruiterView ? (
              <small className="appliedStatus" style={{ color: "#5A8BFF" }}>
                Viewed
              </small>
            ) : (
              <small className="appliedStatus">Applied</small>
            )}
          </span>
        )}
        <span className="jobTitle">
          {jobDetails?.designation
            ? jobDetails?.designation[0]?.designationName
            : ""}
        </span>
        <span className="companyName">{jobDetails?.companyName}</span>
        <span className="skillsKeyword">
          {/* {jobDetails?.skillString?.map((item, i) => {
            return ( */}
          <span>{jobDetails?.skill?.map((s) => s.name)?.join(", ") ?? ""}</span>
          {/* <span>{jobDetails?.skillString}</span> */}
          {/* )
          })} */}
        </span>
        <div className="jobLocation">
          <img src={jobLocationIcon} alt="" />
          <span className="allLocations">
            {/* {jobDetails?.state?.map((item, i) => {
            return ( */}
            <span>
              {jobDetails?.state?.map((s) => s.stateName)?.join(", ") ?? ""}
            </span>
            {/* <span>{jobDetails?.stateString}</span> */}
            {/* )
          })} */}
          </span>
        </div>
        <span className="jobTypeAndWorkType">
          <small className="jobType">{jobDetails?.jobType?.jobType}</small>{" "}
          <small className="workType">{jobDetails?.workMode?.workMode}</small>
        </span>
        {/* <img src={jobIcon} alt="" /> */}
        <span className="experience">{jobDetails?.experience?.experience}</span>
        {/* <span className="jobUploaded">
          {moment(jobDetails?.postedDateTime).fromNow()}
        </span> */}
      </div>
    </div>
  );
};

export default jobCard;

import React, { useEffect } from 'react'
import './HeroSection.css'
import Employees from '../../assets/Employees.png'
import bannerOne from '../../assets/banner1.png'
import bannerTwo from '../../assets/banner2.png'
import { PiNewspaperClipping } from "react-icons/pi";
import bannerThree from '../../assets/banner3.png'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { FaStar } from "react-icons/fa6";
import moment from 'moment';
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs'
import { getAPICall } from '../../APIMethods/APIMethods'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
const HeroSection = () => {
    const userData = JSON.parse(sessionStorage.getItem('user'))
    const [studentLicenseDetails, setStudentLicenseDetails] = React.useState([])
    const [isTransactionOpen, setIsTransactionOpen] = React.useState(false)

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    let userName = JSON.parse(sessionStorage.getItem('userName'))
    let instituteName = JSON.parse(sessionStorage.getItem('instituteDetails'))
    useEffect(() => {
        getAPICall(Url.getAllTransactionHistory)
            .then((res) => {
                setStudentLicenseDetails(res.DATA)
            })
    }, [])

    const handleTransactionHistory = () => {
        setIsTransactionOpen(true)
    }
    const closeTransaction = () => {
        setIsTransactionOpen(false)
    }
    console.log(instituteName);
    
    return (
        <div className='heroSectionMainDiv'>
            <div className='applicantHeroSection'>
                <div className='applicantTextDiv'>
                    <span className='helloText'>Hello, </span>
                    <h2 className='nameText' title={userName}>{userName}</h2>
                    <span className='roleText'>{PortalUserRole == "ROLE_MANAGER" ? "College Administrator" : "Placement Coordinator"}</span>
                    <div className='institutename'>{instituteName?.[0]?.instituteName ?? ''}</div>
                    {PortalUserRole == "ROLE_MANAGER" ?  <div className='licenseDate'>
                        <span className='premiumText' key={userData?.managerLicensed} style={{ color: userData?.managerLicensed ? '#007749' : 'red' }}>
                            {userData?.managerLicensed ?
                                <>
                                    Active Till : <FaStar color='orange' />
                                    {moment(studentLicenseDetails[0]?.expiryDate).format('DD-MMM-YYYY')}
                                </> :
                                'Subscription not purchased'}
                        </span>
                        {/* <span >Active Till :  {studentLicenseDetails ? moment(studentLicenseDetails[0]?.expireDate).format('DD-MMM-YYYY') : ' not purchse'} </span> */}
                        {userData?.managerLicensed ?
                            <button
                                className='viewTransactionBtn'
                                title='View Transaction History'
                                onClick={() => handleTransactionHistory()}>
                                <PiNewspaperClipping />
                            </button> : null}
                    </div> : null }
                </div>
                <div className='applicantEmployeeDiv'>
                    <img src={Employees} alt="" />
                </div>
            </div>
            {/* <div className='applicantHeroSection'>
            <div className='applicantTextDiv'>
                <span className='helloText'>Hello, </span>
                <h2 className='nameText'>John Doe</h2>
                <span className='roleText'>Applicant</span>
            </div>
            <div className='applicantEmployeeDiv'>
            <img src={Employees} alt="" />
            </div>
        </div> */}
            <div className='applicantSliderHeroSection'>
            {/* <img src={instituteName[0]?.instituteLogo} alt="" /> */}

                {/* <div className="sliderSectionOne">
                <img src={bannerOne} alt="" />
            </div>
            <div className="sliderSectionOne">
                <img src={bannerTwo} alt="" />
            </div>
            <div className="sliderSectionOne">
                <img src={bannerThree} alt="" />
            </div> */}
                <Slider {...settings}>
                    <div className="sliderSectionOne">
                        <img src={bannerOne} alt="Banner One" />
                    </div>
                    <div className="sliderSectionOne">
                        <img src={bannerTwo} alt="Banner Two" />
                    </div>
                    <div className="sliderSectionOne">
                        <img src={bannerThree} alt="Banner Three" />
                    </div>
                </Slider>
            </div>
            
            <ComponentAndScreenConstant.CommonModal
                isOpen={isTransactionOpen}
                onRequestClose={closeTransaction}
                className="transaction_modal"
            >
                <div className='transactionModal'>
                    <button onClick={closeTransaction} className='closeButton'>x</button>
                    <h1>Transaction History</h1>
                    <div className='tableDiv'>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Payment ID</th>
                                    <th>Amount</th>
                                    <th>Purchase Date</th>
                                    <th>Expiry Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentLicenseDetails?.map((item, index) => (
                                    <tr key={item?.razorpayPaymentId}>
                                        <td>{index + 1}</td>
                                        <td>{item?.razorpayPaymentId}</td>
                                        <td>{item?.cost}</td>
                                        <td>{moment(item?.purchasedDate).format('DD-MMM-YYYY')}</td>
                                        <td>{moment(item?.expiryDate).format('DD-MMM-YYYY')}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </ComponentAndScreenConstant.CommonModal>
        </div>
    )
}

export default HeroSection
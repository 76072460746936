export const field = {
    fieldValue: '',
    isValidField: true,
    errorField: ""
};

export const CheckImage = /^(https?:)?\/\/[^"'\s]+\.(png|jpe?g|gif)$/i;

const GSTRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}Z[0-9A-Z]{1}$/;
const CharacterRegex = /^[a-zA-Z ]+$/;
const CharacterRegexWithoutSpace = /^[a-zA-Z]+$/;
const NumberRegex = /^[0-9]\d*$/;
const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; //---new 
const studentRegex = /^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/; //---old

const AddressRegex = /^(?!\d+$)[a-zA-Z0-9&() ]*$/;

const ReturnValue = (value, regex, name = '') => {
    let val = value?.trim()
    let newField = {
        fieldValue: value,
        isValidField: (val?.length > 0) ? (!!regex ? (regex?.test(val) ? true : false) : true) : false,
        // errorField: (val?.length > 0) ? (!!regex ? (regex?.test(val) ? '' : !!name ? `Invalid ${name}` : `Invalid Input`) : '') : !!name ? `${name} is required` : `This field is required`
        errorField: (val?.length > 0) 
        ? (!!regex ? (regex?.test(val) ? '' : !!name ? `Invalid ${name}` : `Invalid Input`) : '') 
        : (name === 'companyName' ? 'Company Name is required' : !!name ? `${name} is required` : `This field is required`)
    }
    return newField
}

const validateReturnValue = (value, isValid, error) => {
    return {
        fieldValue: value,
        isValidField: isValid,
        errorField: error
    }
}

export const anythingExceptOnlySpace = (name, val) => {
    return ReturnValue(val)
}

export const GSTValue = (name, val) => {
    return ReturnValue(val, GSTRegex, name)
}

//validation only allow alphabates, required field and apply regex according to field name
export const onlyAlphabets = (name, val) => {
    let regex;
    switch (String(name)?.toLowerCase()) {
        case "promocode name":
            return ReturnValue(val, AddressRegex, name)
        case "email address":
            return ReturnValue(val, EmailRegex, name)
        case "expiry date":
            regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
            return ReturnValue(val, regex, name)
        case "address":
            return ReturnValue(val, AddressRegex, name)
        case "postgraduniversity":
            return ReturnValue(val, CharacterRegexWithoutSpace, name)
        default:
            return ReturnValue(val, CharacterRegex, name)
    }
};

export const onEmailChange = (name, val) => {
    return ReturnValue(val, EmailRegex, name)
}

export const onStudentEmailChange = (name, val) => {
    return ReturnValue(val, studentRegex, name)
}

//validation only allow number, required field and length of the number
export const onlyNumber = (name = null, val = '', len = Infinity) => {
    const regex = /^[0-9]*$/;
    if (!regex.test(val)) {
        val = val.replace(/[^0-9]/g, '');
    }
    return ReturnValue(val, NumberRegex, name)
}

//validation only allow number, required field and length of the number

//validation only allow number, required field and length of the number
export const onlyNumberwithDot = (name = null, val = '', len = Infinity) => {
    const regex = /^(100(\.00?)?|[0-9]{1,2}(\.[0-9]{1,2})?)$/; 

    if (!regex.test(val)) {
        val = val.replace(/[^0-9.]/g, ''); 
    }
    return ReturnValue(val, regex, name)
}
//validation only allow number greater than zero
export const onlyNumberGreaterThanzero = (name = null, val = '') => {
    return ReturnValue(val, NumberRegex, name)
}

export const onlyPincode = (val, len = 6, name = null) => {
    if (val.length <= len) {
        return ReturnValue(val, NumberRegex, name)
    }
}

export const onAllowAllCharacters = (name, val) => {
    return ReturnValue(val)
}

export const AddressValidation = (val) => {
    return ReturnValue(val)
}

export const onlyAlphabetsWithAnd = (name, val) => {
    let regex = /^[a-zA-Z& ]+$/;
    return ReturnValue(val, regex, name)
}

export const onlyAlphabetsWithComa = (name, val) => {
    let regex = /^[a-zA-Z&, ]+$/;
    return ReturnValue(val, regex, name)
}
export const allowAllCharacters = (name, val) => {
    return ReturnValue(val)
}

export const alphabetsWithNotOnlyNumber = (name, val) => {
    let regex = /^(?=.*[0-9])[a-zA-Z0-9]+$/;
    return ReturnValue(val, regex, name)
}

export const MyValidation = (regex, name, val) => {
    return ReturnValue(val, regex, name)
}

export const onlyAlphabetsWithRoundBracketComma = (name, val) => {
    let regex = /^[(),a-zA-Z& ]+$/;
    return ReturnValue(val, regex, name)
}

export const jobDescription = (name, val) => {
    const descRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    return ReturnValue(val, descRegex, name)
}
/////////////////////////////////////////////////////////////////////////////////////////////.............................................................................................................

export const companyValidation = (name, val) => {
    let NameRegex = /^(?=.*[a-zA-Z])([a-zA-Z0-9&.\s]+)$/
    if (name == "Company Description") {
        NameRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    } else if (name == "Company Website Link") {
        // NameRegex = /^(https:\/\/)?www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/
        NameRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/?$/
    } else if (name == "Corporate Address") {
        NameRegex = /^(?!^[0-9]*$)(?!^[^\w\s]*$)(?!^\.$)(?=.*[A-Za-z])[\w\W]*$/
    }

    if (val?.trim().length !== 0) {
        if (NameRegex?.test(val?.trim())) {
            if (name == "Company Website Link") {
                if (val.includes("www.")) {
                    let cutout = val.substring(val.indexOf("www.") + 4)
                    if (NameRegex.test(cutout)) {
                        return validateReturnValue(val, true, '')
                    } else {
                        return validateReturnValue(val, false, 'Invalid input')
                    }
                } else {
                    if (NameRegex?.test(val?.trim())) {
                        return validateReturnValue(val, true, '')
                    } else {
                        return validateReturnValue(val, false, 'Invalid input')
                    }
                }
            } else {
                return validateReturnValue(val, true, '')
            }
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, false, `${name} is required`)
    }
}

export const onlyLinkedInProfile = (name, val) => {
    let linkedinRegex;
    if (val?.toLowerCase()?.includes("link")) {
        linkedinRegex = /^(https?:\/\/)?(www\.|in\.)?linkedin\.com\/(?:[a-zA-Z]{2,}\/)?(in|pub|company|groups)\/[a-zA-Z0-9_-]+\/?(?:\?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=(android_app|ios_app))?$/i;
    } else {
        linkedinRegex = /^((http:\/\/www\.shareresume\.co\/#\/|https:\/\/www\.shareresume\.co\/#\/|https:\/\/shareresume\.co\/#\/|www\.shareresume\.co\/#\/|shareresume\.co\/#\/)|(http:\/\/13\.52\.157\.11:8080\/resume_builder_int\/#\/))[a-zA-Z0-9_-]/;
    }

    // Return early if val is blank or empty
    if (!val?.trim()) {
        return validateReturnValue(val, true, ''); // No error, valid case
    }

    if (linkedinRegex?.test(val.trim())) {
        return validateReturnValue(val, true, '');
    } else {
        return validateReturnValue(val, false, 'Invalid input');
    }
};

export const Calendlyaccount = (name, val) => {
    if (!(!!val?.trim()) || val?.trim() === "https://www.calendly.com" || val?.trim() === "https://calendly.com") {
        return validateReturnValue(val, true, '')
    } else {
        if (/^https:\/\/(?:www\.)?calendly\.com\/[a-zA-Z0-9_-]+$/.test(val?.trim())) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    }

}

export const onPassword = (val) => {
    if (val?.trim().length !== 0) {
        if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,16}$/.test(val)) {
            // if (/^(?=.*\d)(?=.*[a-zA-Z])[\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,20}$/.test(val)) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Password must contain at least 1 lower case, 1 upper case, 1 numeric, special character, length maximum of 16 and minimum of 8')
        }
    } else {
        return validateReturnValue(val, false, 'This Field is required value must be start with character or number.')
    }
}

export const onlySalaryEntry = (field, val) => {
    if (val?.length == 0) {
        return validateReturnValue(val, true, '')
    }
    else if (/^(?!-)(?!.*[-.]{2})[0-9]+(?:\.[0-9])?(?:-[0-9]+(?:\.[0-9])?)?$/.test(val)) {
        return validateReturnValue(val, true, '')
    } else {
        return validateReturnValue(val, false, 'Invalid Salary details')
    }
}

export const matchPassword = (val1, val2) => {
    console.log("wertyuytrewqerty");

    if (val1 === val2) {
        return validateReturnValue(val1, true, '')
    } else {
        return validateReturnValue(val1, false, 'Passwords did not match')
    }
}

export const nonRequiredNum = (val, len) => {
    if (val !== '' || val?.trim().length !== 0) {
        if (/^[1-9]\d*$/.test(val?.trim()) && val?.trim().length === len) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, true, '')
    }
}

export const emailValidationForJobPoster = (name, val) => {
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.)(?!yahoo|gmail|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|hotmail\.)(?!yahoo|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const onlyCorporateMails = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; //---old

    if (val?.trim().length !== 0) {
        if (onlyCorporateMails.test(val?.trim())) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else {
        return validateReturnValue(val, false, name !== null ? `${name} is required` : `This field is required`)
    }
}

export const emailValidationNonMandatory = (name, val) => {
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|gmail\.|hotmail\.)(?!yahoo|gmail|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^[a-zA-Z0-9._%+-]+@(?!yahoo\.|hotmail\.)(?!yahoo|hotmail)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    // const onlyCorporateMails = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const onlyCorporateMails = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/; //---old

    if (val?.trim().length !== 0) {
        if (onlyCorporateMails.test(val?.trim())) {
            return validateReturnValue(val, true, '')
        } else {
            return validateReturnValue(val, false, 'Invalid input')
        }
    } else if(val?.trim().length === 0){
        return validateReturnValue(val, false, 'Company Email Id is required')
    }else {
        return validateReturnValue(val, true, "")
    }
}


export const mobileNumberValidations = (name, value) => {
    value = value.replace(/[^0-9]/g, '');

    if (value.length >= 4 && /^0+$/.test(value)) {
        return validateReturnValue(value, false, "All numbers should not be zero.");
    }

    if (/0{5,}/.test(value) || value.length < 4 || value.length > 10) {
        return validateReturnValue(value, false, "Please Enter a Valid Mobile Number.");
    }

    return ReturnValue(value, NumberRegex, name);
};


export const validateAndFormatURL = (value) => {
    let formattedURL = value.trim();

    const specialCharsRegex = /[^a-zA-Z0-9-._~:/?#\[\]@!$&'()*+,;=%]/g;

    if (specialCharsRegex.test(formattedURL)) {
        formattedURL = formattedURL.replace(specialCharsRegex, "");
        return { formattedURL, error: "Special characters are not allowed in the URL." };
    }

    const validURLRegex = /^https:\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z.]{2,24}$/;

    if (formattedURL.startsWith('https://www.')) {
        if (validURLRegex.test(formattedURL)) {
            return { formattedURL, error: "" };
        }
        return { formattedURL, error: "Invalid URL format. Please enter a valid URL like 'https://www.example.com'." };
    }

    if (formattedURL.startsWith('www.')) {
        formattedURL = `https://${formattedURL}`;
        return { formattedURL, error: "" };
    }

    if (formattedURL.startsWith('http://')) {
        formattedURL = formattedURL.replace(/^http:\/\//, 'https://www.');
        return { formattedURL, error: "" };
    }

    // Handle domain-only input without protocols, assuming "https://www." format
    if (/^[a-zA-Z0-9-]+\.[a-zA-Z.]{2,24}$/.test(formattedURL)) {
        formattedURL = `https://www.${formattedURL}`;
        return { formattedURL, error: "" };
    }

    // Invalid URL error
    return {
        formattedURL: value,
        error: "Invalid URL format. Please enter a valid URL like 'https://www.example.com'.",
    };
};

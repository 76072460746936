import React, { useEffect, useState } from 'react'
import './Header.css'
import proxyIcon from '../../assets/proxyIcon.svg'
import assistanceIcon from '../../assets/assistanceIcon.svg'
import notificationIcon from '../../assets/bellIcon.svg'
import logoutIconArow from '../../assets/logoutArrow.svg'
import logoutIcon from '../../assets/logOut.svg'
import viewProfileIcon from '../../assets/viewProfile.svg'
import logOutLogo from '../../assets/logOutLogo.svg'
import { useNavigate } from 'react-router-dom'
import Modal from "react-modal";
import { ApiCall, getAPICall } from '../../APIMethods/APIMethods'
import { Url } from '../../EnvoirnmentSetup/APIs'
import { ComponentAndScreenConstant } from '../../constants/componentAndScreenConstant'
import { useSelectedCardContext } from '../../Context/Context'
import { PortalUserRole } from './../../EnvoirnmentSetup/APIs';


const Header = () => {

  const navigate = useNavigate()
  const { isLoading, setIsLoading, setWarning, setAlert } = useSelectedCardContext()

  let userName = JSON.parse(sessionStorage.getItem('userName'))

  const [lgOutPopup, setLgOutPopup] = React.useState(false)
  const [feedback, setFeedback] = React.useState('')
  const [isFeedBackOpen, setIsFeedBackOpen] = React.useState(false)
  const [notificationCount, setNotificationCount] = useState(0)

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
    overlay: {
      zIndex: 1
    },
  };

  useEffect(() => {
    getAPICall(Url.getNotificationCount)
      .then((res) => {
        setNotificationCount(res.DATA)
      })
  }, [isLoading])

  const handleLogout = () => {
    setLgOutPopup(false)
    sessionStorage.clear()
    navigate('/login')
  }

  const submitFeedBack = () => {
    if (feedback.trim() === '') {
      setWarning('Please enter your feedback.');
      return
    } else if (feedback.trim().length < 15) {
      setWarning('Feedback should be contain at least 15 characters.');
      return
    }
    setIsLoading(true)
    ApiCall(Url.SendFeedBack, 'POST', true, 'Feedback details', { feedback })
      .then((response) => {
        closeFeedBack()
        setIsLoading(false)
        if (response.SUCCESS) {
          setAlert('Your feedback has been recorded successfully.');
        } else {
          setWarning(response.message)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const closeFeedBack = () => {
    setFeedback('')
    setIsFeedBackOpen(false)
  }
  const [showMenu, setShowMenu] = useState(false)
const handleShowMenu = () =>{
  setShowMenu(!showMenu)
}
  return (
    <div className="headerMainDiv">
      <div className="headerText" style={{ visibility: "hidden" }}>
        <span>Welcome To QuikHire</span>
      </div>
      <div className="profileSection">
        <div className="assistanceIconDiv">
          <button
            className="assistanceBtn"
            onClick={() => setIsFeedBackOpen(true)}
          >
            <img src={assistanceIcon} alt="" />
          </button>
        </div>
        <div
          className={`notificationIconDiv ${
            window.location.href.includes("notification")
              ? "activeNotification"
              : ""
          }`}
          onClick={() => {
            navigate("/Main/notification", { state: { headerHide: true } });
          }}
        >
          <span className="notificationCount">{notificationCount}</span>
          <button className="notificationBtn">
            <img src={notificationIcon} alt="" />
          </button>
        </div>
        <div
          className="proxyMainDiv"
          onClick={() => {
            handleShowMenu();
          }}
        >
          <button className="ProxyBtn">
            <img src={proxyIcon} alt="" />
          </button>{" "}
          <span className="loggedInName">
            <span> {userName} </span>
            <small>{PortalUserRole == "ROLE_MANAGER" ? "College Administrator" : "Placement Coordinator"}</small>
          </span>
          <img className="arrowIcon" src={logoutIconArow} alt="" />
          {showMenu && (
            <div className="proxyOption">
              <button
                type="button"
                className="logoutBTN"
                onClick={() => {
                  navigate("/Main/userProfile", {
                    state: { headerHide: true },
                  });
                }}
              >
                {" "}
                <img className="arrowIcon" src={viewProfileIcon} alt="" /> View
                Profile
              </button>
              <button
                type="button"
                className="logoutBTN"
                onClick={() => {
                  setLgOutPopup(true);
                }}
              >
                {" "}
                <img className="arrowIcon" src={logoutIcon} alt="" /> Logout
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={lgOutPopup}
        onRequestClose={() => setLgOutPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div
            className="delAccModalCrossDiv"
            style={{ backgroundColor: "#FFF4F4" }}
          >
            <p
              onClick={() => setLgOutPopup(false)}
              className="delAccModalCrossPara"
              style={{ color: "#BC0000" }}
            >
              ×
            </p>
          </div>
        </div>
        <div
          className="logoutLogoDiv"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logOutLogo} />
        </div>
        <div
          className="delAccModalErrMsg"
          style={{ fontWeight: "normal", marginBottom: 0 }}
        >
          Would you like to logout?
        </div>
        <div
          className="okBtnModal"
          style={{ display: "flex", gap: "2%", margin: "0 0 2% 0" }}
        >
          <button
            onClick={() => setLgOutPopup(false)}
            style={{
              backgroundColor: "#ffff",
              border: "0.5px solid #BC0000",
              color: "#BC0000",
              cursor: "pointer",
            }}
          >
            Cancel
          </button>
          <button
            style={{ backgroundColor: "#BC0000", cursor: "pointer" }}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </Modal>

      <ComponentAndScreenConstant.CommonModal
        isOpen={isFeedBackOpen}
        onRequestClose={closeFeedBack}
      >
        <div className="feedBackModal">
          <button onClick={closeFeedBack} className="closeButton">
            x
          </button>
          <h1>Feedback</h1>
          <div className="feedBackDiv">
            <label>
              Submit Your Feedback <span style={{ color: "red" }}>*</span>
            </label>
            <textarea
              rows={10}
              cols={0}
              maxLength={256}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Write your comments/questions/features requests/issues here."
            />
            <div className="feedbackLength">
              <span>* Feedback must contain at least 15 characters.</span>
              <span>{feedback.length}/256</span>
            </div>
            <div className="buttonDiv">
              <button onClick={closeFeedBack}>Cancel</button>
              <button
                onClick={submitFeedBack}
                style={{ color: "#fff", backgroundColor: "#01774A" }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </ComponentAndScreenConstant.CommonModal>
    </div>
  );
}

export default Header
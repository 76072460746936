import React, { useState, useEffect } from "react";
import './QuickHireJobs.css'
import { Url } from "../../EnvoirnmentSetup/APIs";
import CommonTable from "../../Components/table/CommonTable";
import latestJobIcon from "../../assets/latestJobIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import { getAPICall, postAPICall } from "../../APIMethods/APIMethods";
import { useSelectedCardContext } from "../../Context/Context";

const QuickHireJobs = () => {
  const { selectedCard, setSelectedCard, setIsLoading } = useSelectedCardContext()

  const [jobList, setJobList] = useState([])
  const [jobList2, setJobList2] = useState([])
  // const [preferenceDetails, setPreferenceDetails] = useState({
  //   keywords: [],
  //   location: [],
  //   experience: {},
  //   workMode: [],
  //   jobType: []
  // })
  const [searchVal, setSearchVal] = useState('')
  const [selectType, setSelectType] = useState(1)

  const handleSortChange = (e) => {
    const selectedValue = e.target.value;
    // Implement sorting logic based on selectedValue
    let sortedList = [...jobList]; // Create a copy of jobList to avoid mutating state directly
    if (selectedValue === '1') {
      // Sort by Posted Date
      sortedList?.sort((a, b) => new Date(b.postedDate ?? b.job.postedDate) - new Date(a.postedDate ?? a.job.postedDate));
    } else if (selectedValue === '2') {
      // Sort by Active Date
      sortedList?.sort((a, b) => new Date(a.tillDate ?? a.job.tillDate) - new Date(b.tillDate ?? b.job.tillDate));
    } else /* if (selectedValue === '3')  */ {
      // Sort by Experience
      sortedList?.sort((a, b) => (a?.experience?.experienceId ?? a?.job?.experience?.experienceId) - (b?.experience?.experienceId ?? b?.job?.experience?.experienceId));
    }
    // Update state with sorted list
    setJobList(sortedList);
  }
  const quikHireSortJobs = (value) => {
    let sortedList = [...jobList2];
    if (value === 2) {
      // Sort by Active Date
      sortedList = sortedList.filter((item) => item.experience.experience == 'Intern' ? item : null)
    } else if (value === 3) {
      // Sort by Experience
      sortedList = sortedList.filter((item) => item.experience.experience == 'Fresher' ? item : null)
    }
    // Update state with sorted list
    setJobList(sortedList);
    setSelectType(value)
    setSearchVal('')
  }

  useEffect(() => {
    setIsLoading(true)
    getAPICall(Url.quikhireJobs)
      .then((res) => {
        setIsLoading(false)
        let d = res?.DATA?.map((i) => {
          // if (screenName == 'applied') {
          //   let stateStr = i?.job?.state?.map((s, ind) => s.stateName).join(', ') ?? ''
          //   let skillStr = i?.job?.skill?.map((k, index) => k?.name).join(', ') ?? ''
          //   return { ...i, job: { ...i.job, stateString: stateStr, skillString: skillStr } }
          // } else {
          let stateStr = i?.state?.map((s, ind) => s.stateName).join(', ') ?? ''
          let skillStr = i?.skill?.map((k, index) => k?.name).join(', ') ?? ''
          return { ...i, stateString: stateStr, skillString: skillStr }
          // }
        }) ?? []
        document.getElementsByClassName('sortSelect')[0].selectedIndex = 0;
        setJobList(d)
        setJobList2(d)
      })

    // getAPICall(Url?.getuser)
    //   .then((res) => {
    //     if (res?.SUCCESS && res?.DATA?.preferenceSet) {
    //       setPreferenceDetails({
    //         keywords: res?.DATA?.skill,
    //         location: res?.DATA?.state,
    //         experience: res?.DATA?.experience,
    //         workMode: res?.DATA?.workMode,
    //         jobType: res?.DATA?.jobType,
    //         preferenceSet: res?.DATA?.preferenceSet
    //       })
    //     }
    //   })
  }, [])

  useEffect(() => {
    try {
      if (jobList2?.length > 0) {
        // let newValue = [...jobList2.filter((v) => JSON.stringify(v)?.toLowerCase()?.indexOf(searchVal?.toLowerCase()) > -1)]
        // setJobList([...newValue])
        let filteredJobs =
          //  screenName != 'QuikHire' ? jobList2 : 
          jobList2.filter((item) => {
            // if (selectType == 2) {
            //   return item.experience.experience == 'Intern' ? item : null
            // } else if (selectType == 3) {
            //   return item.experience.experience == 'Fresher' ? item : null
            // } else {
            return item
            // }
          })
        setJobList(filteredJobs?.filter((item) => {
          // if (screenName == 'applied') {
          //   return (
          //     (item?.job?.designation[0]?.designationName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
          //     (item?.job?.companyName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
          //     (item?.job?.stateString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
          //     (item?.job?.skillString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
          //     (item?.job?.experience.experience?.toLowerCase())?.includes(searchVal?.toLowerCase())
          //   )
          // } else {
          return (
            (item?.designation[0]?.designationName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.companyName?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.stateString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.skillString?.toLowerCase())?.includes(searchVal?.toLowerCase()) ||
            (item?.experience.experience?.toLowerCase())?.includes(searchVal?.toLowerCase())
          )
          // }
        }))
      }
    } catch (e) {
      console.log(e);
    }
  }, [searchVal])

  return (
    <div className="quickhirejobswrapper">
      <div className='headingRowDashboardDiv'>
        <div className='jobToggleButtons'>
          <button onClick={() => quikHireSortJobs(1)} className={selectType == 1 ? 'active' : ''}>All Jobs</button>
          <button onClick={() => quikHireSortJobs(2)} className={selectType == 2 ? 'active' : ''}>Internships</button>
          <button onClick={() => quikHireSortJobs(3)} className={selectType == 3 ? 'active' : ''}>Fresher Jobs</button>
        </div>
        <div className='searchAndSortDiv'>
          <div className='search'>
            <img className='searchIcon' src={searchIcon} alt='' />
            <input className='latestJobSearch' type="text" placeholder='Search Jobs' value={searchVal} onChange={(e) => setSearchVal(e?.target?.value)} />
          </div>
          <div className='sort'>
            <select className="sortSelect" name="" id="" onChange={(e) => { handleSortChange(e) }}>
              {/* <option value="">Select</option> */}
              <option value="1">Sort by Posted Date</option>
              <option value="2">Sort by Active Date</option>
              <option value="3">Sort by Experience</option>
            </select>
          </div>
        </div>
      </div>

      <div className='jobCards'>
        {jobList?.length > 0 ? <>
          {jobList?.map((item) => (
            <div className='latestSeperateJobCard' key={item?.jobId} onClick={() => {
              // console.log(screenName == 'applied' ? item?.job?.jobId : item?.jobId);
              // if (screenName == 'QuikHire') {
              // quikHireJobStatus(item?.jobId)
              window.open(`https://quikhire.ai/details.php?jobId=${btoa(item?.jobId)}`, '_blank');
              // } else {
              //   navigate('/Main/jobDetails', { state: { jobId: screenName == 'applied' ? item?.job?.jobId : item?.jobId, headerHide: true, screen: screenName } })
              // }
            }}>
              <ComponentAndScreenConstant.jobCard
                jobDetails={item}
                screenFlow={'quikHireJobs'}
                // applied={screenName == 'applied'}
                applicationStatus={item}
              />
            </div>
          ))}</> : <div className='notFound'>
          <img src={require('../../assets/NotFound.png')} style={{ height: '30vh', width: '30vh', }} />
          No Data Found.
        </div>}
      </div>
    </div>
  )
}

export default QuickHireJobs